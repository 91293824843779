import { render, staticRenderFns } from "./EditTourBaseSettingModel.vue?vue&type=template&id=4235f1eb&scoped=true&"
import script from "./EditTourBaseSettingModel.vue?vue&type=script&lang=js&"
export * from "./EditTourBaseSettingModel.vue?vue&type=script&lang=js&"
import style0 from "./EditTourBaseSettingModel.vue?vue&type=style&index=0&id=4235f1eb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4235f1eb",
  null
  
)

export default component.exports